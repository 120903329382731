/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re starting to experience hearing loss, now is a great time to seek assistance and find a solution. Across the Sunshine State, millions of people experience impaired hearing, and thousands are seeking the right medical and technical solutions as you’re reading this."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Their reasons are diverse. For some people, it’s all about the demands of work. For others, it’s the disruption that hearing problems cause to social life. Some want hearing aids to help them use their cellphones, while others want technology to hook up with their home cinema or audio systems, letting them explore their passion for movies and music the way they always used to."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-affects-floridian-lifestyles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-affects-floridian-lifestyles",
    "aria-label": "hearing loss affects floridian lifestyles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Loss Affects Floridian Lifestyles"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In Florida, having perfect hearing is something that is often taken for granted. It means that people can hear the crowd at the Dolphins or Jaguars games and really get into the action. Individuals can hear the sound of Miami’s Latin and hip-hop scene, share the magic of Disneyworld with our kids or grandkids, soak up the tranquility of the Everglades, and hear every shout of “fore” on the state’s hundreds of great golf courses."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And when Floridians start to lose their hearing, it makes a massive difference. If you’re reading this, you’ve probably started to feel the effects. While the Florida sun shines as bright as ever, the atmosphere at the beach bars and the sound of nature starts to become duller and less distinct."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Conversations become harder and slower as you have to ask people to repeat themselves, while working life also starts to become tough as well. Meetings become a chore, with key details going over your head. Even things like hearing shopping lists starts to become hard, as you return home with the wrong brand of detergent or the wrong ingredients for tonight’s meal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If all of this sounds familiar, there’s no need to suffer the slow approach of hearing loss. Take action now and address your issues so that your everyday life sounds as good as it used to."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "floridas-hearing-loss-situation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#floridas-hearing-loss-situation",
    "aria-label": "floridas hearing loss situation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Florida's Hearing Loss Situation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re experiencing hearing loss, one thing is essential: never feel like you’re alone. In fact, you’re part of a massive community of people in your home state who are seeking assistance to handle their audiological issues."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Around 10 percent of Americans suffer from hearing loss at the moment (a figure that’s rising as the population ages). That means around 2-3 million Floridians struggle with their hearing, probably including people you know and work with."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re a senior, the numbers are even higher, with around one in three experiencing hearing loss by the time they hit 65. But it’s not a problem limited to seniors, far from it. If you work in one of Florida’s high-volume occupations like heavy vehicle operators, sound engineers or factory workers, there’s around a 1:5 chance of sustaining hearing damage through exposure to noise at work."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But even spectators at sporting events can regularly experience hazardous volume levels. At NFL games, the crowd frequently creates 80-90 decibels of noise. When 80 decibels is considered to be a dangerous level, regular supporters can find their hearing affected by following the sports they love."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-can-help-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-can-help-you",
    "aria-label": "hearcom can help you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hear.com Can Help You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The good thing is, if you suffer from hearing loss in Florida there are plenty of options available to explore. At hear.com, we have struck up partnerships with audiologists across the state, making it easy to hook up with the assistance you require wherever you live. In total, we can link you up to more than 250 providers from Jacksonville to Pensacola, and from Key West to Fort Lauderdale."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you arrange a consultation with our Florida providers, you’ll be able to test your hearing and discuss the appropriate solutions to your hearing loss problems. Your audiologist will suggest different types of hearing aid and let you try them on for size."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "That way, you can find a model that has the technological features you need (such as smartphone compatibility or options for differential hearing loss). You can also find a model that suits your style, with plenty of concealed, tiny devices on the market."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "arrange-a-risk-free-trial-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arrange-a-risk-free-trial-today",
    "aria-label": "arrange a risk free trial today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Arrange a Risk-Free Trial Today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To start tackling your hearing aid issues, just visit our website and fill out our questionnaire. After adding a few pieces of information about contact details and your current hearing situation, we’ll connect you with a local audiologist and schedule an appointment in seconds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After that, you’ll be able to find the perfect solution with the assistance of Florida’s leading hearing loss professionals."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
